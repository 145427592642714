<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="赛事" prop="team_status">
        <el-select v-model="type_id" placeholder="请选择" @change="searchClick">
          <el-option value="s1" label="门派预选赛s1"></el-option>
          <el-option value="s1_pro" label="门派联赛s1"></el-option>
          <el-option value="s2" label="门派预选赛s2"></el-option>
          <el-option value="wdl" label="第四届城市联赛"></el-option>
          <el-option value="s2_pro" label="门派联赛s2"></el-option>
          <el-option value="s3" label="门派预选赛s3"></el-option>
           <!-- <el-option value="s3_pro" label="门派联赛s3"></el-option> -->
        </el-select>
      </el-form-item>

      <el-form-item label="年份" prop="status">
        <el-select
          v-model="searchForm.year"
          clearable
          placeholder="请选择"
          @change="searchClick"
        >
          <el-option value="-1" label="全部"></el-option>
          <el-option
            v-for="(item, index) in years"
            :value="item"
            :label="item"
            :key="`year_${index}`"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="当前状态">
        <el-select
          @change="handleDepot()"
          v-model="searchForm.status"
          placeholder="请选择查询条件"
        >
          <el-option label="正常" :value="0"></el-option>
          <el-option label="回收站" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`match:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </perContainer>
      <!-- <el-form-item>
        <el-button type="primary" @click="searchClick">回收站</el-button>
      </el-form-item> -->
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:save`"
            @click.native.stop="handleEdit(scope.row)"
            >编辑赛事</perButton
          >
          <!-- 不设置权限了，用不到暂时 -->
          <el-button
            v-if="matchType == 'league'"
            @click.native.stop="handleLeg(scope.row)"
            >赛段管理</el-button
          >
          <perButton
            v-if="
              scope.row.status != 1 &&
                new Date(scope.row.current_time) >=
                  new Date(scope.row.match_time.sign_up_start)
            "
            :perm="`match:save`"
            @click.native.stop="handleTeam(scope.row)"
            >管理队伍</perButton
          >
          <perButton
            v-if="
              scope.row.status != 1 &&
                new Date(scope.row.current_time) >=
                  new Date(scope.row.match_time.review_start) &&
                matchType != 'league'
            "
            :perm="`match:stage`"
            @click.native.stop="handleStage(scope.row)"
            >赛事阶段</perButton
          >
          <el-button
            v-if="matchType == 'league'"
            @click.native.stop="handleMatch(scope.row)"
            >赛事管理</el-button
          >
          <el-button
            v-if="type_id == 'wdl'"
            @click.native.stop="handleScore(scope.row)"
            >积分管理</el-button
          >
          <perButton
            :perm="`match:banners`"
            @click.native.stop="handleBanner(scope.row)"
            >编辑Banner</perButton
          >
          <perButton
            v-if="scope.row.status != 1 && matchType != 'league'"
            :perm="`match:save`"
            @click.native.stop="handleChangeGroupQRCode(scope.row)"
            >群二维码</perButton
          >
          <perButton
            :perm="`match:create_test_teams`"
            v-if="matchType != 'league' && isProduction"
            @click.native.stop="handleCreateTestTeams(scope.row)"
            >生成500个测试队伍</perButton
          >
          <popconfirmButton
            v-if="scope.row.status != 1"
            :perm="`match:save`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-else
            :perm="`match:save`"
            content="是否确认恢复？"
            text="恢复"
            @onConfirm="handleRecovery(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="'编辑群二维码'"
      :visible.sync="qrcodeVisible"
      :close-on-click-modal="false"
      width="500px"
      v-loading="loading"
    >
      <el-form ref="qrcodeForm" :model="qrcodeData">
        <el-form-item label="社群二维码" prop="expired">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeTopImageUpload"
            action="#"
            drag
            :http-request="httpUploadRequest"
          >
            <img
              v-if="qrcodeData.expired"
              :src="qrcodeData.src"
              class="avatar"
            />
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
            </div>
          </el-upload>
        </el-form-item>

        <div style="color: #9F4648; text-align: center">
          微信群二维码有效期7天，请及时更新社群二维码~
        </div>
        <div
          v-if="qrcodeData.expired"
          style="color: #9F4648; text-align: center"
        >
          截止有效期：{{
            new Date(qrcodeData.expired).format("yyyy-MM-dd hh:mm:ss")
          }}
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { switchMatchType } from "@/utils/switchMatchType";
export default {
  name: "super_list",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
      isProduction() {
        if (process.env.NODE_ENV === 'development') {
          return true
        }
         return false;
    }
  },
   beforeMount() {    
    console.log(process.env.NODE_ENV); // 在页面加载时打印环境变量    
},
  //观测type_id变化，存到本地，属性名为matchType，值为type_id
  watch: {
    //将赛事分类，分成不同的大类
    type_id: {
      handler: function(val) {
        //取到比赛类型
        this.matchType = switchMatchType(val);
        localStorage.setItem("matchType", this.matchType);
      },
      immediate: true,
    },
  },
  data() {
    let years = [];
    for (
      let i = new Date().getFullYear() + 1;
      i >= new Date().getFullYear() - 10;
      i--
    ) {
      years.push(i);
    }

    return {
      matchType: "",
      ids: [],
      type_id: "s3",
      loading: false,
      pageSize: 10,
      years: years,
      qrcodeVisible: false,
      qrcodeData: {
        match_id: "",
        expired: false,
        src: "",
      },
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        year: "",
        status: 0,
      },
      dataTable: [],
      dataList: [
        {
          prop: "year",
          label: "年份",
          width: 300,
        },
        {
          prop: "type_id",
          label: "赛事",
          width: 300,
          formatter: (row) => {
            return this.match_types[row.type_id];
          },
        },
        {
          prop: "match_time",
          label: "赛事阶段",
          width: 200,
          formatter: (row) => {
            let curentTime = new Date(row.current_time);
            //获取赛事类型
            let matchType = localStorage.getItem("matchType");
            if (matchType != "league") {
              if (curentTime < new Date(row.match_time.sign_up_start)) {
                return "未开始";
              } else if (curentTime < new Date(row.match_time.review_start)) {
                return "报名期";
              } else if (curentTime < new Date(row.match_time.match_start)) {
                return "审核期";
              } else if (curentTime < new Date(row.match_time.match_end)) {
                return "比赛期";
              } else {
                return "已结束";
              }
            } else {
              let curentTime = new Date(row.current_time);
              //联赛赛事阶段
              if (curentTime < new Date(row.match_time.match_start)) {
                return "未开始";
              } else if (curentTime < new Date(row.match_time.match_end)) {
                return "进行中";
              } else {
                return "已结束";
              }
            }
          },
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "正常";
              case 1:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
    };
  },
  methods: {
    async handleChangeGroupQRCode(row) {
      console.log()
      this.loading = true;
      let { data, errorCode } = await this.$http.match.group_qr_expired(
        row._id
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.qrcodeData.expired = (data && data.expired) || false;
      if (data && data.expired) {
        this.qrcodeData.src =
          data.cdn_url +
          "/lrs_community/match/s1/images/qrcode/" +
          row._id +
          "_qr.jpg?r=" +
          Math.random();
      }

      this.qrcodeData.match_id = row._id;

      this.qrcodeVisible = true;
    },
    add() {
      this.removeTabByPath(`/match/info/info_s1/add`);
      this.removeTabByPath(`/match/info/info_s1/edit`);
      console.log('type_id',this.type_id);
      this.$router.push({
        path: `/match/info/info_s1/add`,
        query: {
          type_id: this.type_id,
          view_type: "add",
        },
      });
    },
    //赛段管理
    handleLeg(row) {
      this.$router.push({
        path: `/match/info/info_s1/legManage`,
        query: { _id: row._id, type_id: this.type_id },
      });
    },
    async handleEdit(row) {
      this.removeTabByPath(`/match/info/info_s1/add`);
      this.removeTabByPath(`/match/info/info_s1/edit`);
      this.$router.push({
        path: `/match/info/info_s1/edit`,
        query: { _id: row._id, view_type: "edit", type_id: this.type_id },
      });
    },
    handleBanner(row) {
      this.removeTabByPath(`/match/info/info_s1/banners`);
      this.$router.push({
        path: `/match/info/info_s1/banners`,
        query: { _id: row._id, type_id: this.type_id },
      });
    },
    handleCreateTestTeams(row) {
      this.$http.postByUrl(`/admin/match/${this.type_id}/create_test_teams`, {
        match_id: row._id,
      });
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.deleteInfo(row._id);
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      row.status = data.status;
      this.handleQuery();
    },
    async handleRecovery(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.recovery(row._id);
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });

      row.status = data.status;
      this.handleQuery();
    },
    handleTeam(row) {
      this.removeTabByPath(`/match/info/info_s1/team`);
      this.$router.push({
        path: `/match/info/info_s1/team`,
        query: { _id: row._id, type_id: this.type_id },
      });
    },
    handleStage(row) {
      this.removeTabByPath(`/match/info/info_s1/stage`);
      this.$router.push({
        path: `/match/info/info_s1/stage`,
        query: { _id: row._id, type_id: this.type_id },
      });
    },
    //联赛场次管理
    handleMatch(row) {
      this.removeTabByPath(`/match/info/info_info_s1/session`);
      this.$router.push({
        path: `/match/info/info_s1/session`,
        query: { match_id: row._id, type_id: this.type_id },
      });
    },
    //积分管理
    handleScore(row) {
      this.removeTabByPath(`/match/info/info_s1/rank`);
      this.$router.push({
        path: `/match/info/info_s1/rank`,
        query: { _id: row._id, match_id: row._id, type_id: this.type_id }, //_id同match_id，写两个的原因是因为组件有的用的_id，有的用的match_id
      });
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.match.list({
        ...this.searchForm,
        type_id: this.type_id,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = this.qrcodeData.match_id + "_qr.jpg";
      console.log(fileName);
      let path = "lrs_community/match/s1/images/qrcode/" + fileName;
      let result = await this.$http.uploadFile(
        path,
        fileName,
        item.file,
        "no-cache"
      );
      console.log(result);
      if (result) {
        let { data, errorCode } = await this.$http.match.set_group_qr_expired(
          this.qrcodeData.match_id
        );
        if (errorCode != "0000") {
          this.loading = false;
          return;
        }

        this.qrcodeData.expired = data.expired;
        this.qrcodeData.src =
          data.cdn_url +
          "/lrs_community/match/s1/images/qrcode/" +
          this.qrcodeData.match_id +
          "_qr.jpg?r=" +
          Math.random();
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    onMatchChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id);
      if (current) {
        current.match_time = data.match_time;
        current.status = data.status;
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.handleQuery();
    this.$bus.$off("onMatchChanged", this.onMatchChanged);
    this.$bus.$on("onMatchChanged", this.onMatchChanged);
  },
  destroyed() {
    this.$bus.$off("onMatchChanged", this.onMatchChanged);
  },
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px !important;
  height: 300px !important;
  line-height: 300px !important;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 300px !important;
  height: 300px !important;
}
.avatar {
  width: 300px !important;
  height: 300px !important;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
