//导出转赛事类型函数
export function switchMatchType(type_id) {
  switch (type_id) {
    case "s1":
    case "s2":
    case "s3":
      return "preliminaries"; //预选赛
    case "s1_pro":
    case "s2_pro":
      return "league"; //联赛
    case "wdl":
      return "league"; //第四届城市联赛
    case "elimination":
      return "knockout "; //淘汰赛
    default:
      return "preliminaries"; //预选赛
  }
}
